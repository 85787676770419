<template>
  <v-expansion-panels
    focusable
    v-model="panel"
    @change="search = ''"
  >
    <v-text-field
      v-model="searchTables"
      append-icon="mdi-magnify"
      label="Procurar bases"
      hide-details
      single-line
      dense
      class="mt-0 mb-5"
      @click.stop=""
    />
    <v-progress-linear
      indeterminate
      v-show="loading"
    />
    <v-expansion-panel
      v-for="(estruturaBase, key) in estruturasBasesFiltradas"
      :key="key"
      :disabled="loading"
    >
      <v-expansion-panel-header :title="estruturaBase.descricao">
        {{ estruturaBase.nome_tabela }}
        <v-text-field
          v-if="key === panel"
          v-model="search"
          append-icon="mdi-magnify"
          label="Procurar campos"
          hide-details
          single-line
          dense
          class="mx-10 mt-0"
          @click.stop=""
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content class="content-expansion-p-rel-customizado">
        <v-data-table
          v-if="'estrutura' in estruturaBase"
          class="table-rel-customizado"
          :headers="headers"
          :items="estruturaBase.estrutura"
          :items-per-page="-1"
          :mobile-breakpoint="0"
          :search.sync="search"
          disable-sort
          hide-default-footer
          fixed-header
          height="300px"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import BasesDadosService from '@/services/BasesDadosService';

export default {
  data: () => ({
    estruturasBases: [],
    panel: [],
    headers: [
      { text: 'Nome do Campo', value: 'column' },
      { text: 'Tipo de Dados', value: 'type' }
    ],
    search: '',
    searchTables: '',
    loading: false
  }),
  mounted() {
    this.getBasesDadosLiberadas();
  },
  computed: {
    estruturasBasesFiltradas() {
      if (this.searchTables) {
        return this.estruturasBases.filter((base) =>
          base.nome_tabela.includes(this.searchTables.toLowerCase())
        );
      }
      return this.estruturasBases;
    }
  },
  methods: {
    getBasesDadosLiberadas() {
      this.loading = true;
      BasesDadosService.getBasesDadosLiberadas()
        .then((response) => (this.estruturasBases = response.data))
        .catch((error) => {
          console.log(error);
          this.$toast.error('Erro ao recuperar bases de dados liberadas.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },
    getEstrutura(tabela) {
      this.loading = true;
      return BasesDadosService.getBasesDadosCampos(tabela)
        .then((response) => response)
        .catch((error) => {
          console.log(error);
          this.$toast.error(
            'Erro ao recuperar estrutura da bases de dados.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    async manageEstruturaTabela(position) {
      let baseInfo = this.estruturasBasesFiltradas[position];
      if (baseInfo && 'estrutura' in baseInfo == false) {
        let estrutura = await this.getEstrutura(baseInfo.nome_tabela);
        this.$set(
          this.estruturasBasesFiltradas[position],
          'estrutura',
          estrutura
        );
      }
    }
  },
  watch: {
    panel: {
      handler(index) {
        this.manageEstruturaTabela(index);
      },
      deep: true
    }
  }
};
</script>

<style>
.content-expansion-p-rel-customizado .v-expansion-panel-content__wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.table-rel-customizado table tr td {
  font-size: 13px !important;
}
.table-rel-customizado table tr th {
  font-size: 13px !important;
  font-weight: 400;
}
</style>
