import APIService from './APIService';

export default {
  getBasesDadosLiberadas() {
    return APIService.apiCall().get('bases-dados/get-bases-dados-liberadas');
  },
  getBasesDadosGeograficasLiberadas(
    companyId = null,
    dataRegistro = null,
    versao = null
  ) {
    let queryString = 'dados_geograficos=1';
    if (companyId && dataRegistro && versao) {
      queryString += `&company_id=${companyId}&data_registro=${dataRegistro}&versao=${versao}`;
    }
    return APIService.apiCall().get(
      `bases-dados/get-bases-dados-liberadas?${queryString}`
    );
  },
  getBasesDadosLiberadasAnalisesSetorizadas() {
    return APIService.apiCall().get(
      'bases-dados/get-bases-dados-liberadas-analises-setorizadas'
    );
  },
  getBasesDadosCampos(baseDados, bdgdVersaoId=0) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`bases-dados/get-bases-dados-campos/${baseDados}?bdgd_versao_id=${bdgdVersaoId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },
  getBaseDadosMaiorLancamento(
    baseDados,
    retornaMesDados,
    retornaBdgdVersao,
    entidade
  ) {
    retornaBdgdVersao = Boolean(retornaBdgdVersao);
    let url = `/bases-dados/get-base-dados-maior-lancamento/${baseDados}/${retornaMesDados}/${retornaBdgdVersao}`;
    url += entidade ? `/${entidade}` : '';
    return APIService.apiCall().get(url);
  }
};
